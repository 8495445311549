import { PRODUCT_IMAGE_BASE_FRAGMENT } from '@/graphql/_Fragments/ProductImage/Base';
import {
  EXHIBITOR_ACTION_BUTTON_MESSAGE_FRAGMENT,
} from '@/graphql/_Fragments/Exhibitor/ActionButtons/Message';

export const PRODUCT_ACTION_BUTTON_MESSAGE_FRAGMENT = `
  fragment productActionButtonMessageFragment on Product {
    uid
    name
    images(first: 1) {
      ...productImageBaseFragment
    }
    exhibitor {
      ...exhibitorActionButtonMessageFragment
    }
  }
  ${PRODUCT_IMAGE_BASE_FRAGMENT}
  ${EXHIBITOR_ACTION_BUTTON_MESSAGE_FRAGMENT}
`;
